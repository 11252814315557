





































import { computed, defineComponent, ref } from '@vue/composition-api';

import ImageDialog from '@/components/ImageDialog.vue';

export default defineComponent({
  name: 'ProfileEa',
  components: {
    ImageDialog
  },
  setup: () => {
    const yearData = ref([
      {
        year: '2018',
        description: 'Jahresabschluss',
        images: [
          require('@/assets/ehrenabteilung/2018/dsci0082.jpg'),
          require('@/assets/ehrenabteilung/2018/dsci0083.jpg'),
          require('@/assets/ehrenabteilung/2018/dsci0084.jpg'),
          require('@/assets/ehrenabteilung/2018/dsci0085.jpg'),
          require('@/assets/ehrenabteilung/2018/dsci0086.jpg'),
          require('@/assets/ehrenabteilung/2018/dsci0087.jpg'),
          require('@/assets/ehrenabteilung/2018/dsci0088.jpg'),
          require('@/assets/ehrenabteilung/2018/dsci0089.jpg'),
          require('@/assets/ehrenabteilung/2018/dsci0090.jpg'),
          require('@/assets/ehrenabteilung/2018/dsci0091.jpg'),
          require('@/assets/ehrenabteilung/2018/dsci0092.jpg'),
          require('@/assets/ehrenabteilung/2018/dsci0094.jpg'),
          require('@/assets/ehrenabteilung/2018/dsci0095.jpg'),
          require('@/assets/ehrenabteilung/2018/dsci0096.jpg'),
          require('@/assets/ehrenabteilung/2018/dsci0097.jpg'),
          require('@/assets/ehrenabteilung/2018/dsci0098.jpg'),
          require('@/assets/ehrenabteilung/2018/dsci0099.jpg'),
          require('@/assets/ehrenabteilung/2018/dsci0100.jpg'),
          require('@/assets/ehrenabteilung/2018/dsci0101.jpg'),
          require('@/assets/ehrenabteilung/2018/dsci0103.jpg'),
          require('@/assets/ehrenabteilung/2018/dsci0104.jpg'),
          require('@/assets/ehrenabteilung/2018/dsci0105.jpg'),
          require('@/assets/ehrenabteilung/2018/dsci0108.jpg'),
          require('@/assets/ehrenabteilung/2018/dsci0111.jpg'),
          require('@/assets/ehrenabteilung/2018/dsci0114.jpg'),
          require('@/assets/ehrenabteilung/2018/dsci0115.jpg'),
          require('@/assets/ehrenabteilung/2018/dsci0116.jpg'),
          require('@/assets/ehrenabteilung/2018/dsci0118.jpg'),
          require('@/assets/ehrenabteilung/2018/dsci0119.jpg'),
          require('@/assets/ehrenabteilung/2018/dsci0120.jpg'),
          require('@/assets/ehrenabteilung/2018/dsci0122.jpg'),
          require('@/assets/ehrenabteilung/2018/dsci0123.jpg'),
          require('@/assets/ehrenabteilung/2018/dsci0126.jpg'),
          require('@/assets/ehrenabteilung/2018/dsci0127.jpg')
        ]
      },
      {
        year: '2017',
        description: 'Jahresabschluss',
        images: [
          require('@/assets/ehrenabteilung/2017/20171229_164739.jpg'),
          require('@/assets/ehrenabteilung/2017/20171229_171932.jpg'),
          require('@/assets/ehrenabteilung/2017/20171229_172022.jpg'),
          require('@/assets/ehrenabteilung/2017/20171229_172030.jpg'),
          require('@/assets/ehrenabteilung/2017/20171229_1721031.jpg'),
          require('@/assets/ehrenabteilung/2017/20171229_172731.jpg'),
          require('@/assets/ehrenabteilung/2017/20171229_1727361.jpg'),
          require('@/assets/ehrenabteilung/2017/20171229_172741.jpg'),
          require('@/assets/ehrenabteilung/2017/20171229_172746.jpg'),
          require('@/assets/ehrenabteilung/2017/20171229_172757.jpg'),
          require('@/assets/ehrenabteilung/2017/20171229_172804.jpg'),
          require('@/assets/ehrenabteilung/2017/20171229_174141.jpg'),
          require('@/assets/ehrenabteilung/2017/20171229_174817.jpg'),
          require('@/assets/ehrenabteilung/2017/dsci0004.jpg'),
          require('@/assets/ehrenabteilung/2017/dsci0005.jpg'),
          require('@/assets/ehrenabteilung/2017/dsci0006.jpg'),
          require('@/assets/ehrenabteilung/2017/dsci0007.jpg'),
          require('@/assets/ehrenabteilung/2017/dsci0008.jpg'),
          require('@/assets/ehrenabteilung/2017/dsci0009.jpg'),
          require('@/assets/ehrenabteilung/2017/dsci0010.jpg'),
          require('@/assets/ehrenabteilung/2017/dsci0011.jpg'),
          require('@/assets/ehrenabteilung/2017/dsci0012.jpg'),
          require('@/assets/ehrenabteilung/2017/dsci0013.jpg'),
          require('@/assets/ehrenabteilung/2017/dsci0014.jpg'),
          require('@/assets/ehrenabteilung/2017/dsci0015.jpg'),
          require('@/assets/ehrenabteilung/2017/dsci0016.jpg')
        ]
      },
      {
        year: '2016',
        description: 'Weihnachtsfeier',
        images: [
          require('@/assets/ehrenabteilung/2016/dsci0001.jpg'),
          require('@/assets/ehrenabteilung/2016/dsci0003.jpg'),
          require('@/assets/ehrenabteilung/2016/dsci0004.jpg'),
          require('@/assets/ehrenabteilung/2016/dsci0005.jpg'),
          require('@/assets/ehrenabteilung/2016/dsci0006.jpg'),
          require('@/assets/ehrenabteilung/2016/dsci0007.jpg'),
          require('@/assets/ehrenabteilung/2016/dsci0008.jpg'),
          require('@/assets/ehrenabteilung/2016/dsci0009.jpg'),
          require('@/assets/ehrenabteilung/2016/dsci0010.jpg'),
          require('@/assets/ehrenabteilung/2016/dsci0011.jpg'),
          require('@/assets/ehrenabteilung/2016/dsci0012.jpg'),
          require('@/assets/ehrenabteilung/2016/dsci0013.jpg'),
          require('@/assets/ehrenabteilung/2016/dsci0014.jpg'),
          require('@/assets/ehrenabteilung/2016/dsci0015.jpg'),
          require('@/assets/ehrenabteilung/2016/dsci0017.jpg'),
          require('@/assets/ehrenabteilung/2016/dsci0019.jpg'),
          require('@/assets/ehrenabteilung/2016/dsci0021.jpg'),
          require('@/assets/ehrenabteilung/2016/dsci0022.jpg'),
          require('@/assets/ehrenabteilung/2016/dsci0023.jpg'),
          require('@/assets/ehrenabteilung/2016/dsci0025.jpg'),
          require('@/assets/ehrenabteilung/2016/dsci0026.jpg'),
          require('@/assets/ehrenabteilung/2016/dsci0028.jpg'),
          require('@/assets/ehrenabteilung/2016/dsci0029.jpg'),
          require('@/assets/ehrenabteilung/2016/dsci0031.jpg'),
          require('@/assets/ehrenabteilung/2016/dsci0032.jpg'),
          require('@/assets/ehrenabteilung/2016/dsci0033.jpg'),
          require('@/assets/ehrenabteilung/2016/dsci0035.jpg'),
          require('@/assets/ehrenabteilung/2016/dsci0039.jpg'),
          require('@/assets/ehrenabteilung/2016/dsci0041.jpg'),
          require('@/assets/ehrenabteilung/2016/dsci0042.jpg'),
          require('@/assets/ehrenabteilung/2016/dsci0044.jpg'),
          require('@/assets/ehrenabteilung/2016/dsci0046.jpg'),
          require('@/assets/ehrenabteilung/2016/dsci0048.jpg'),
          require('@/assets/ehrenabteilung/2016/dsci0049.jpg'),
          require('@/assets/ehrenabteilung/2016/dsci0050.jpg'),
          require('@/assets/ehrenabteilung/2016/dsci0052.jpg'),
          require('@/assets/ehrenabteilung/2016/dsci0053.jpg'),
          require('@/assets/ehrenabteilung/2016/dsci0054.jpg'),
          require('@/assets/ehrenabteilung/2016/dsci0055.jpg'),
          require('@/assets/ehrenabteilung/2016/dsci0057.jpg'),
          require('@/assets/ehrenabteilung/2016/dsci0058.jpg'),
          require('@/assets/ehrenabteilung/2016/dsci0059.jpg')
        ]
      },
      {
        year: '2016',
        description: 'Treffen Alverskirchen und Everswinkel',
        images: [
          require('@/assets/ehrenabteilung/2016/elw/dsci0060.jpg'),
          require('@/assets/ehrenabteilung/2016/elw/dsci0061.jpg'),
          require('@/assets/ehrenabteilung/2016/elw/dsci0062.jpg'),
          require('@/assets/ehrenabteilung/2016/elw/dsci0063.jpg'),
          require('@/assets/ehrenabteilung/2016/elw/dsci0064.jpg'),
          require('@/assets/ehrenabteilung/2016/elw/dsci0065.jpg'),
          require('@/assets/ehrenabteilung/2016/elw/dsci0066.jpg'),
          require('@/assets/ehrenabteilung/2016/elw/dsci0068.jpg'),
          require('@/assets/ehrenabteilung/2016/elw/dsci0069.jpg'),
          require('@/assets/ehrenabteilung/2016/elw/dsci0070.jpg'),
          require('@/assets/ehrenabteilung/2016/elw/dsci0071.jpg'),
          require('@/assets/ehrenabteilung/2016/elw/dsci0072.jpg'),
          require('@/assets/ehrenabteilung/2016/elw/dsci0073.jpg'),
          require('@/assets/ehrenabteilung/2016/elw/dsci0079.jpg'),
          require('@/assets/ehrenabteilung/2016/elw/dsci0082.jpg'),
          require('@/assets/ehrenabteilung/2016/elw/dsci0084.jpg'),
          require('@/assets/ehrenabteilung/2016/elw/dsci0085.jpg'),
          require('@/assets/ehrenabteilung/2016/elw/dsci0086.jpg'),
          require('@/assets/ehrenabteilung/2016/elw/dsci0087.jpg'),
          require('@/assets/ehrenabteilung/2016/elw/dsci0088.jpg'),
          require('@/assets/ehrenabteilung/2016/elw/dsci0089.jpg'),
          require('@/assets/ehrenabteilung/2016/elw/dsci0090.jpg'),
          require('@/assets/ehrenabteilung/2016/elw/dsci0091.jpg'),
          require('@/assets/ehrenabteilung/2016/elw/dsci0092.jpg'),
          require('@/assets/ehrenabteilung/2016/elw/dsci0093.jpg'),
          require('@/assets/ehrenabteilung/2016/elw/dsci0094.jpg'),
          require('@/assets/ehrenabteilung/2016/elw/dsci0095.jpg'),
          require('@/assets/ehrenabteilung/2016/elw/dsci0096.jpg'),
          require('@/assets/ehrenabteilung/2016/elw/dsci0097.jpg')
        ]
      },
      {
        year: '2016',
        description: 'Ausflug zur Berufsfeuerwehr Münster',
        images: [
          require('@/assets/ehrenabteilung/2016_MS/dsci0001.jpg'),
          require('@/assets/ehrenabteilung/2016_MS/dsci0002.jpg'),
          require('@/assets/ehrenabteilung/2016_MS/dsci0003.jpg'),
          require('@/assets/ehrenabteilung/2016_MS/dsci0004.jpg'),
          require('@/assets/ehrenabteilung/2016_MS/dsci0006.jpg'),
          require('@/assets/ehrenabteilung/2016_MS/dsci0007.jpg'),
          require('@/assets/ehrenabteilung/2016_MS/dsci0008.jpg'),
          require('@/assets/ehrenabteilung/2016_MS/dsci0010.jpg'),
          require('@/assets/ehrenabteilung/2016_MS/dsci0011.jpg'),
          require('@/assets/ehrenabteilung/2016_MS/dsci0013.jpg'),
          require('@/assets/ehrenabteilung/2016_MS/dsci0014.jpg'),
          require('@/assets/ehrenabteilung/2016_MS/dsci0015.jpg'),
          require('@/assets/ehrenabteilung/2016_MS/dsci0016.jpg'),
          require('@/assets/ehrenabteilung/2016_MS/dsci0017.jpg'),
          require('@/assets/ehrenabteilung/2016_MS/dsci0018.jpg'),
          require('@/assets/ehrenabteilung/2016_MS/dsci0021.jpg'),
          require('@/assets/ehrenabteilung/2016_MS/dsci0022.jpg'),
          require('@/assets/ehrenabteilung/2016_MS/dsci0023.jpg'),
          require('@/assets/ehrenabteilung/2016_MS/dsci0024.jpg'),
          require('@/assets/ehrenabteilung/2016_MS/dsci0025.jpg'),
          require('@/assets/ehrenabteilung/2016_MS/dsci0027.jpg'),
          require('@/assets/ehrenabteilung/2016_MS/dsci0028.jpg'),
          require('@/assets/ehrenabteilung/2016_MS/dsci0030.jpg'),
          require('@/assets/ehrenabteilung/2016_MS/dsci0032.jpg'),
          require('@/assets/ehrenabteilung/2016_MS/dsci0034.jpg'),
          require('@/assets/ehrenabteilung/2016_MS/dsci0035.jpg'),
          require('@/assets/ehrenabteilung/2016_MS/dsci0036.jpg'),
          require('@/assets/ehrenabteilung/2016_MS/dsci0038.jpg'),
          require('@/assets/ehrenabteilung/2016_MS/dsci0039.jpg'),
          require('@/assets/ehrenabteilung/2016_MS/dsci0040.jpg'),
          require('@/assets/ehrenabteilung/2016_MS/dsci0041.jpg'),
          require('@/assets/ehrenabteilung/2016_MS/dsci0043.jpg'),
          require('@/assets/ehrenabteilung/2016_MS/dsci0044.jpg'),
          require('@/assets/ehrenabteilung/2016_MS/dsci0045.jpg'),
          require('@/assets/ehrenabteilung/2016_MS/dsci0046.jpg'),
          require('@/assets/ehrenabteilung/2016_MS/dsci0047.jpg'),
          require('@/assets/ehrenabteilung/2016_MS/dsci0048.jpg'),
          require('@/assets/ehrenabteilung/2016_MS/dsci0049.jpg'),
          require('@/assets/ehrenabteilung/2016_MS/dsci0050.jpg'),
          require('@/assets/ehrenabteilung/2016_MS/dsci0051.jpg'),
          require('@/assets/ehrenabteilung/2016_MS/dsci0053.jpg'),
          require('@/assets/ehrenabteilung/2016_MS/dsci0055.jpg'),
          require('@/assets/ehrenabteilung/2016_MS/dsci0056.jpg'),
          require('@/assets/ehrenabteilung/2016_MS/dsci0057.jpg'),
          require('@/assets/ehrenabteilung/2016_MS/dsci0058.jpg'),
          require('@/assets/ehrenabteilung/2016_MS/dsci0059.jpg'),
          require('@/assets/ehrenabteilung/2016_MS/dsci0060.jpg'),
          require('@/assets/ehrenabteilung/2016_MS/dsci0061.jpg'),
          require('@/assets/ehrenabteilung/2016_MS/dsci0062.jpg'),
          require('@/assets/ehrenabteilung/2016_MS/dsci0063.jpg'),
          require('@/assets/ehrenabteilung/2016_MS/dsci0064.jpg'),
          require('@/assets/ehrenabteilung/2016_MS/dsci0066.jpg'),
          require('@/assets/ehrenabteilung/2016_MS/dsci0068.jpg'),
          require('@/assets/ehrenabteilung/2016_MS/dsci0069.jpg'),
          require('@/assets/ehrenabteilung/2016_MS/dsci0073.jpg'),
          require('@/assets/ehrenabteilung/2016_MS/dsci0074.jpg')
        ]
      },
      {
        year: '2015',
        description: 'Winteressen',
        images: [
          require('@/assets/ehrenabteilung/2015/dsci0111.jpg'),
          require('@/assets/ehrenabteilung/2015/dsci0124.jpg'),
          require('@/assets/ehrenabteilung/2015/dsci0125.jpg'),
          require('@/assets/ehrenabteilung/2015/dsci0126.jpg'),
          require('@/assets/ehrenabteilung/2015/dsci0127.jpg'),
          require('@/assets/ehrenabteilung/2015/dsci0128.jpg'),
          require('@/assets/ehrenabteilung/2015/dsci0129.jpg'),
          require('@/assets/ehrenabteilung/2015/dsci0130.jpg'),
          require('@/assets/ehrenabteilung/2015/dsci0131.jpg'),
          require('@/assets/ehrenabteilung/2015/dsci0132.jpg'),
          require('@/assets/ehrenabteilung/2015/dsci0133.jpg'),
          require('@/assets/ehrenabteilung/2015/dsci0134.jpg'),
          require('@/assets/ehrenabteilung/2015/dsci0135.jpg'),
          require('@/assets/ehrenabteilung/2015/dsci0136.jpg'),
          require('@/assets/ehrenabteilung/2015/dsci0137.jpg'),
          require('@/assets/ehrenabteilung/2015/dsci0138.jpg'),
          require('@/assets/ehrenabteilung/2015/dsci0139.jpg'),
          require('@/assets/ehrenabteilung/2015/dsci0140.jpg'),
          require('@/assets/ehrenabteilung/2015/dsci0141.jpg'),
          require('@/assets/ehrenabteilung/2015/dsci0142.jpg'),
          require('@/assets/ehrenabteilung/2015/dsci0144.jpg'),
          require('@/assets/ehrenabteilung/2015/dsci0145.jpg'),
          require('@/assets/ehrenabteilung/2015/dsci0146.jpg'),
          require('@/assets/ehrenabteilung/2015/dsci0147.jpg'),
          require('@/assets/ehrenabteilung/2015/dsci0148.jpg'),
          require('@/assets/ehrenabteilung/2015/dsci0149.jpg'),
          require('@/assets/ehrenabteilung/2015/dsci0150.jpg'),
          require('@/assets/ehrenabteilung/2015/dsci0151.jpg'),
          require('@/assets/ehrenabteilung/2015/dsci0152.jpg'),
          require('@/assets/ehrenabteilung/2015/dsci0153.jpg'),
          require('@/assets/ehrenabteilung/2015/dsci0154.jpg'),
          require('@/assets/ehrenabteilung/2015/dsci0155.jpg'),
          require('@/assets/ehrenabteilung/2015/dsci0156.jpg'),
          require('@/assets/ehrenabteilung/2015/dsci0157.jpg'),
          require('@/assets/ehrenabteilung/2015/dsci0158.jpg'),
          require('@/assets/ehrenabteilung/2015/dsci0159.jpg'),
          require('@/assets/ehrenabteilung/2015/dsci0160.jpg'),
          require('@/assets/ehrenabteilung/2015/dsci0161.jpg')
        ]
      },
      {
        year: '2012',
        description: 'Winteressen',
        images: [
          require('@/assets/ehrenabteilung/2012/2012-12-14 18.18.17.jpg'),
          require('@/assets/ehrenabteilung/2012/2012-12-14 18.18.41.jpg'),
          require('@/assets/ehrenabteilung/2012/2012-12-14 18.18.57.jpg'),
          require('@/assets/ehrenabteilung/2012/2012-12-14 18.19.13.jpg'),
          require('@/assets/ehrenabteilung/2012/2012-12-14 18.19.27.jpg'),
          require('@/assets/ehrenabteilung/2012/2012-12-14 18.19.51.jpg'),
          require('@/assets/ehrenabteilung/2012/2012-12-14 18.22.07.jpg'),
          require('@/assets/ehrenabteilung/2012/2012-12-14 18.22.23.jpg'),
          require('@/assets/ehrenabteilung/2012/2012-12-14 18.22.58.jpg'),
          require('@/assets/ehrenabteilung/2012/2012-12-14 19.18.58.jpg'),
          require('@/assets/ehrenabteilung/2012/2012-12-14 20.00.24.jpg'),
          require('@/assets/ehrenabteilung/2012/2012-12-14 20.00.38.jpg'),
          require('@/assets/ehrenabteilung/2012/2012-12-14 20.01.04.jpg'),
          require('@/assets/ehrenabteilung/2012/2012-12-14 20.01.17.jpg'),
          require('@/assets/ehrenabteilung/2012/2012-12-14 20.01.56.jpg'),
          require('@/assets/ehrenabteilung/2012/2012-12-14 20.02.42.jpg'),
          require('@/assets/ehrenabteilung/2012/2012-12-14 20.05.18.jpg'),
          require('@/assets/ehrenabteilung/2012/2012-12-14 20.07.26.jpg'),
          require('@/assets/ehrenabteilung/2012/2012-12-14 20.08.03.jpg'),
          require('@/assets/ehrenabteilung/2012/2012-12-14 20.11.19.jpg'),
          require('@/assets/ehrenabteilung/2012/2012-12-14 20.11.50.jpg'),
          require('@/assets/ehrenabteilung/2012/2012-12-14 20.12.07.jpg'),
          require('@/assets/ehrenabteilung/2012/2012-12-14 20.12.49.jpg'),
          require('@/assets/ehrenabteilung/2012/2012-12-14 20.13.27.jpg'),
          require('@/assets/ehrenabteilung/2012/2012-12-14 20.13.45.jpg'),
          require('@/assets/ehrenabteilung/2012/2012-12-14 20.14.04.jpg'),
          require('@/assets/ehrenabteilung/2012/2012-12-14 20.14.24.jpg')
        ]
      },
      {
        year: '2012',
        description: 'Heinrichenburg',
        images: [
          require('@/assets/ehrenabteilung/2012/2012-08-18 09.12.32.jpg'),
          require('@/assets/ehrenabteilung/2012/2012-08-18 09.12.51.jpg'),
          require('@/assets/ehrenabteilung/2012/2012-08-18 09.13.03.jpg'),
          require('@/assets/ehrenabteilung/2012/2012-08-18 09.13.39.jpg'),
          require('@/assets/ehrenabteilung/2012/2012-08-18 09.14.13.jpg'),
          require('@/assets/ehrenabteilung/2012/2012-08-18 09.14.39.jpg'),
          require('@/assets/ehrenabteilung/2012/2012-08-18 09.15.19.jpg'),
          require('@/assets/ehrenabteilung/2012/2012-08-18 09.17.57.jpg'),
          require('@/assets/ehrenabteilung/2012/2012-08-18 09.18.17.jpg'),
          require('@/assets/ehrenabteilung/2012/2012-08-18 09.21.22.jpg'),
          require('@/assets/ehrenabteilung/2012/2012-08-18 09.21.34.jpg'),
          require('@/assets/ehrenabteilung/2012/2012-08-18 09.21.46.jpg'),
          require('@/assets/ehrenabteilung/2012/2012-08-18 09.21.53.jpg'),
          require('@/assets/ehrenabteilung/2012/2012-08-18 10.52.56.jpg'),
          require('@/assets/ehrenabteilung/2012/2012-08-18 10.53.08.jpg'),
          require('@/assets/ehrenabteilung/2012/2012-08-18 10.53.18.jpg'),
          require('@/assets/ehrenabteilung/2012/2012-08-18 10.53.31.jpg'),
          require('@/assets/ehrenabteilung/2012/2012-08-18 10.53.46.jpg'),
          require('@/assets/ehrenabteilung/2012/2012-08-18 10.54.07.jpg'),
          require('@/assets/ehrenabteilung/2012/2012-08-18 10.54.18.jpg'),
          require('@/assets/ehrenabteilung/2012/2012-08-18 10.54.25.jpg'),
          require('@/assets/ehrenabteilung/2012/2012-08-18 12.29.25.jpg'),
          require('@/assets/ehrenabteilung/2012/2012-08-18 12.47.57.jpg'),
          require('@/assets/ehrenabteilung/2012/2012-08-18 12.56.35.jpg'),
          require('@/assets/ehrenabteilung/2012/2012-08-18 13.06.20.jpg'),
          require('@/assets/ehrenabteilung/2012/2012-08-18 13.12.39.jpg'),
          require('@/assets/ehrenabteilung/2012/2012-08-18 13.12.50.jpg'),
          require('@/assets/ehrenabteilung/2012/2012-08-18 13.15.09.jpg'),
          require('@/assets/ehrenabteilung/2012/2012-08-18 13.15.17.jpg'),
          require('@/assets/ehrenabteilung/2012/2012-08-18 13.25.52.jpg'),
          require('@/assets/ehrenabteilung/2012/2012-08-18 13.26.12.jpg'),
          require('@/assets/ehrenabteilung/2012/2012-08-18 13.26.21.jpg'),
          require('@/assets/ehrenabteilung/2012/2012-08-18 13.52.24.jpg'),
          require('@/assets/ehrenabteilung/2012/2012-08-18 13.58.16.jpg'),
          require('@/assets/ehrenabteilung/2012/2012-08-18 17.08.12.jpg'),
          require('@/assets/ehrenabteilung/2012/2012-08-18 17.08.38.jpg'),
          require('@/assets/ehrenabteilung/2012/2012-08-18 17.08.51.jpg'),
          require('@/assets/ehrenabteilung/2012/2012-08-18 17.18.15.jpg'),
          require('@/assets/ehrenabteilung/2012/2012-08-18 17.18.29.jpg'),
          require('@/assets/ehrenabteilung/2012/2012-08-18 17.18.54.jpg'),
          require('@/assets/ehrenabteilung/2012/2012-08-18 17.19.06.jpg')
        ]
      },
      {
        year: '2011',
        description: 'Claas',
        images: [
          require('@/assets/ehrenabteilung/2011/2011 feuerwehrausflug heimatmuseum 038.jpg'),
          require('@/assets/ehrenabteilung/2011/2011 feuerwehrausflug heimatmuseum 039.jpg'),
          require('@/assets/ehrenabteilung/2011/2011 feuerwehrausflug heimatmuseum 040.jpg'),
          require('@/assets/ehrenabteilung/2011/2011 feuerwehrausflug heimatmuseum 041.jpg'),
          require('@/assets/ehrenabteilung/2011/2011 feuerwehrausflug heimatmuseum 042.jpg'),
          require('@/assets/ehrenabteilung/2011/2011 feuerwehrausflug heimatmuseum 043.jpg'),
          require('@/assets/ehrenabteilung/2011/2011 feuerwehrausflug heimatmuseum 044.jpg'),
          require('@/assets/ehrenabteilung/2011/2011 feuerwehrausflug heimatmuseum 045.jpg'),
          require('@/assets/ehrenabteilung/2011/2011 feuerwehrausflug heimatmuseum 046.jpg'),
          require('@/assets/ehrenabteilung/2011/2011 feuerwehrausflug heimatmuseum 047.jpg')
        ]
      },
      {
        year: '2011',
        description: 'Mitmachmuseum',
        images: [
          require('@/assets/ehrenabteilung/2011/2011 feuerwehrausflug heimatmuseum 001.jpg'),
          require('@/assets/ehrenabteilung/2011/2011 feuerwehrausflug heimatmuseum 002.jpg'),
          require('@/assets/ehrenabteilung/2011/2011 feuerwehrausflug heimatmuseum 003.jpg'),
          require('@/assets/ehrenabteilung/2011/2011 feuerwehrausflug heimatmuseum 004.jpg'),
          require('@/assets/ehrenabteilung/2011/2011 feuerwehrausflug heimatmuseum 005.jpg'),
          require('@/assets/ehrenabteilung/2011/2011 feuerwehrausflug heimatmuseum 006.jpg'),
          require('@/assets/ehrenabteilung/2011/2011 feuerwehrausflug heimatmuseum 007.jpg'),
          require('@/assets/ehrenabteilung/2011/2011 feuerwehrausflug heimatmuseum 008.jpg'),
          require('@/assets/ehrenabteilung/2011/2011 feuerwehrausflug heimatmuseum 009.jpg'),
          require('@/assets/ehrenabteilung/2011/2011 feuerwehrausflug heimatmuseum 010.jpg'),
          require('@/assets/ehrenabteilung/2011/2011 feuerwehrausflug heimatmuseum 011.jpg'),
          require('@/assets/ehrenabteilung/2011/2011 feuerwehrausflug heimatmuseum 012.jpg'),
          require('@/assets/ehrenabteilung/2011/2011 feuerwehrausflug heimatmuseum 013.jpg'),
          require('@/assets/ehrenabteilung/2011/2011 feuerwehrausflug heimatmuseum 014.jpg'),
          require('@/assets/ehrenabteilung/2011/2011 feuerwehrausflug heimatmuseum 015.jpg'),
          require('@/assets/ehrenabteilung/2011/2011 feuerwehrausflug heimatmuseum 016.jpg'),
          require('@/assets/ehrenabteilung/2011/2011 feuerwehrausflug heimatmuseum 017.jpg'),
          require('@/assets/ehrenabteilung/2011/2011 feuerwehrausflug heimatmuseum 018.jpg'),
          require('@/assets/ehrenabteilung/2011/2011 feuerwehrausflug heimatmuseum 019.jpg'),
          require('@/assets/ehrenabteilung/2011/2011 feuerwehrausflug heimatmuseum 020.jpg')
        ]
      },
      {
        year: '2010',
        description: 'Radtour',
        images: [
          require('@/assets/ehrenabteilung/2010/2010 radtour ehrenabteilung 001.jpg'),
          require('@/assets/ehrenabteilung/2010/2010 radtour ehrenabteilung 002.jpg'),
          require('@/assets/ehrenabteilung/2010/2010 radtour ehrenabteilung 003.jpg'),
          require('@/assets/ehrenabteilung/2010/2010 radtour ehrenabteilung 004.jpg'),
          require('@/assets/ehrenabteilung/2010/2010 radtour ehrenabteilung 005.jpg'),
          require('@/assets/ehrenabteilung/2010/2010 radtour ehrenabteilung 006.jpg'),
          require('@/assets/ehrenabteilung/2010/2010 radtour ehrenabteilung 007.jpg'),
          require('@/assets/ehrenabteilung/2010/2010 radtour ehrenabteilung 008.jpg'),
          require('@/assets/ehrenabteilung/2010/2010 radtour ehrenabteilung 009.jpg'),
          require('@/assets/ehrenabteilung/2010/2010 radtour ehrenabteilung 010.jpg'),
          require('@/assets/ehrenabteilung/2010/2010 radtour ehrenabteilung 011.jpg'),
          require('@/assets/ehrenabteilung/2010/2010 radtour ehrenabteilung 012.jpg'),
          require('@/assets/ehrenabteilung/2010/2010 radtour ehrenabteilung 013.jpg'),
          require('@/assets/ehrenabteilung/2010/2010 radtour ehrenabteilung 014.jpg'),
          require('@/assets/ehrenabteilung/2010/2010 radtour ehrenabteilung 015.jpg'),
          require('@/assets/ehrenabteilung/2010/2010 radtour ehrenabteilung 016.jpg'),
          require('@/assets/ehrenabteilung/2010/2010 radtour ehrenabteilung 017.jpg'),
          require('@/assets/ehrenabteilung/2010/2010 radtour ehrenabteilung 018.jpg'),
          require('@/assets/ehrenabteilung/2010/2010 radtour ehrenabteilung 019.jpg'),
          require('@/assets/ehrenabteilung/2010/2010 radtour ehrenabteilung 020.jpg'),
          require('@/assets/ehrenabteilung/2010/2010 radtour ehrenabteilung 021.jpg'),
          require('@/assets/ehrenabteilung/2010/2010 radtour ehrenabteilung 022.jpg'),
          require('@/assets/ehrenabteilung/2010/2010 radtour ehrenabteilung 023.jpg'),
          require('@/assets/ehrenabteilung/2010/2010 radtour ehrenabteilung 024.jpg'),
          require('@/assets/ehrenabteilung/2010/2010 radtour ehrenabteilung 025.jpg'),
          require('@/assets/ehrenabteilung/2010/2010 radtour ehrenabteilung 026.jpg'),
          require('@/assets/ehrenabteilung/2010/2010 radtour ehrenabteilung 027.jpg'),
          require('@/assets/ehrenabteilung/2010/2010 radtour ehrenabteilung 028.jpg'),
          require('@/assets/ehrenabteilung/2010/2010 radtour ehrenabteilung 029.jpg'),
          require('@/assets/ehrenabteilung/2010/2010 radtour ehrenabteilung 030.jpg'),
          require('@/assets/ehrenabteilung/2010/2010 radtour ehrenabteilung 031.jpg'),
          require('@/assets/ehrenabteilung/2010/2010 radtour ehrenabteilung 032.jpg')
        ]
      },
      {
        year: '2010',
        description: 'Winteressen',
        images: [
          require('@/assets/ehrenabteilung/2010/2010 winteressen 001.jpg'),
          require('@/assets/ehrenabteilung/2010/2010 winteressen 002.jpg'),
          require('@/assets/ehrenabteilung/2010/2010 winteressen 003.jpg'),
          require('@/assets/ehrenabteilung/2010/2010 winteressen 004.jpg'),
          require('@/assets/ehrenabteilung/2010/2010 winteressen 005.jpg'),
          require('@/assets/ehrenabteilung/2010/2010 winteressen 006.jpg'),
          require('@/assets/ehrenabteilung/2010/2010 winteressen 007.jpg'),
          require('@/assets/ehrenabteilung/2010/2010 winteressen 008.jpg'),
          require('@/assets/ehrenabteilung/2010/2010 winteressen 009.jpg'),
          require('@/assets/ehrenabteilung/2010/2010 winteressen 010.jpg'),
          require('@/assets/ehrenabteilung/2010/2010 winteressen 011.jpg'),
          require('@/assets/ehrenabteilung/2010/2010 winteressen 012.jpg'),
          require('@/assets/ehrenabteilung/2010/2010 winteressen 013.jpg'),
          require('@/assets/ehrenabteilung/2010/2010 winteressen 014.jpg'),
          require('@/assets/ehrenabteilung/2010/2010 winteressen 015.jpg'),
          require('@/assets/ehrenabteilung/2010/2010 winteressen 017.jpg'),
          require('@/assets/ehrenabteilung/2010/2010 winteressen 018.jpg'),
          require('@/assets/ehrenabteilung/2010/2010 winteressen 019.jpg'),
          require('@/assets/ehrenabteilung/2010/2010 winteressen 020.jpg'),
          require('@/assets/ehrenabteilung/2010/2010 winteressen 021.jpg'),
          require('@/assets/ehrenabteilung/2010/2010 winteressen 022.jpg'),
          require('@/assets/ehrenabteilung/2010/2010 winteressen 023.jpg'),
          require('@/assets/ehrenabteilung/2010/2010 winteressen 024.jpg'),
          require('@/assets/ehrenabteilung/2010/2010 winteressen 025.jpg'),
          require('@/assets/ehrenabteilung/2010/2010 winteressen 026.jpg'),
          require('@/assets/ehrenabteilung/2010/2010 winteressen 027.jpg'),
          require('@/assets/ehrenabteilung/2010/2010 winteressen 028.jpg'),
          require('@/assets/ehrenabteilung/2010/2010 winteressen 029.jpg'),
          require('@/assets/ehrenabteilung/2010/2010 winteressen 030.jpg'),
          require('@/assets/ehrenabteilung/2010/2010 winteressen 031.jpg'),
          require('@/assets/ehrenabteilung/2010/2010 winteressen 032.jpg'),
          require('@/assets/ehrenabteilung/2010/2010 winteressen 033.jpg'),
          require('@/assets/ehrenabteilung/2010/2010 winteressen 034.jpg'),
          require('@/assets/ehrenabteilung/2010/2010 winteressen 035.jpg')
        ]
      },
      {
        year: '2009',
        description: 'Winteressen',
        images: [
          require('@/assets/ehrenabteilung/2009/2009 feuerwehr winteressen 001.jpg'),
          require('@/assets/ehrenabteilung/2009/2009 feuerwehr winteressen 002.jpg'),
          require('@/assets/ehrenabteilung/2009/2009 feuerwehr winteressen 003.jpg'),
          require('@/assets/ehrenabteilung/2009/2009 feuerwehr winteressen 004.jpg'),
          require('@/assets/ehrenabteilung/2009/2009 feuerwehr winteressen 005.jpg'),
          require('@/assets/ehrenabteilung/2009/2009 feuerwehr winteressen 007.jpg'),
          require('@/assets/ehrenabteilung/2009/2009 feuerwehr winteressen 008.jpg'),
          require('@/assets/ehrenabteilung/2009/2009 feuerwehr winteressen 009.jpg'),
          require('@/assets/ehrenabteilung/2009/2009 feuerwehr winteressen 010.jpg'),
          require('@/assets/ehrenabteilung/2009/2009 feuerwehr winteressen 011.jpg'),
          require('@/assets/ehrenabteilung/2009/2009 feuerwehr winteressen 012.jpg'),
          require('@/assets/ehrenabteilung/2009/2009 feuerwehr winteressen 013.jpg'),
          require('@/assets/ehrenabteilung/2009/2009 feuerwehr winteressen 014.jpg'),
          require('@/assets/ehrenabteilung/2009/2009 feuerwehr winteressen 015.jpg'),
          require('@/assets/ehrenabteilung/2009/2009 feuerwehr winteressen 018.jpg'),
          require('@/assets/ehrenabteilung/2009/2009 feuerwehr winteressen 019.jpg'),
          require('@/assets/ehrenabteilung/2009/2009 feuerwehr winteressen 020.jpg'),
          require('@/assets/ehrenabteilung/2009/2009 feuerwehr winteressen 021.jpg'),
          require('@/assets/ehrenabteilung/2009/2009 feuerwehr winteressen 023.jpg'),
          require('@/assets/ehrenabteilung/2009/2009 feuerwehr winteressen 024.jpg'),
          require('@/assets/ehrenabteilung/2009/2009 feuerwehr winteressen 025.jpg'),
          require('@/assets/ehrenabteilung/2009/2009 feuerwehr winteressen 026.jpg'),
          require('@/assets/ehrenabteilung/2009/2009 feuerwehr winteressen 028.jpg'),
          require('@/assets/ehrenabteilung/2009/2009 feuerwehr winteressen 029.jpg')
        ]
      }
    ]);

    const showDialog = ref(false);

    const selectedIdx = ref(-1);
    const selectedImages = computed(() => {
      const idx = selectedIdx.value;
      if (idx >= 0) {
        return yearData.value[idx].images;
      }
    });
    const selectedTitle = computed(() => {
      const idx = selectedIdx.value;
      if (idx >= 0) {
        return `${yearData.value[idx].description} ${yearData.value[idx].year}`;
      }
    });

    return {
      yearData,
      showDialog,
      selectedIdx,
      selectedImages,
      selectedTitle
    };
  }
});
