




































import { computed, defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'ImageDialog',
  props: {
    showDialog: {
      type: Boolean,
      default: false
    },
    images: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: ''
    }
  },
  model: {
    prop: 'showDialog',
    event: 'showDialogChanged'
  },
  setup: (props, ctx) => {
    const dialogModel = computed({
      get: () => props.showDialog,
      set: value => ctx.emit('showDialogChanged', value)
    });

    return {
      dialogModel
    };
  }
});
